module.exports = [{
      plugin: require('/home/travis/build/waxpoetic/wonderbars/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-32186780-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/home/travis/build/waxpoetic/wonderbars/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/waxpoetic/wonderbars/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
